<template>
  <div>
    <Environment v-if="environment != 'production'"/>
    <cmsHeader />
    <Toast />
    <div class="container">
      <div class="content">
      <div class="title">Events : Edit </div>
      Select Active to show your event on the Events or Summer Sessions page or select Pending 
      to temporarily hide the event. Enter the information below and click the Submit button to 
      submit your event.
    </div>
    <div class="form-container">
      <div id="statusId" class="form-container-section">
        <span class="form-container-label">Status * : </span>
        <span class="form-container-input">
          <RadioButton id="status1" name="statusId" v-model="event.statusId" :value="Number(1)" /> <label for="status1">Active</label>
          <RadioButton id="status2" name="statusId" v-model="event.statusId" :value="Number(2)" /> <label for="status2">Pending</label>
        </span>
      </div>
      <div id="isSummerSession" class="form-container-section">
        <span class="form-container-label">Summer Session * : </span>
        <span class="form-container-input">
          <RadioButton id="session1" name="isSummerSession" :value="true" v-model="event.isSummerSession" /> <label for="session1">Yes</label>
          <RadioButton id="session2" name="isSummerSession" :value="false" v-model="event.isSummerSession" /> <label for="session1">No</label>
        </span>
      </div>
      <div id="title" class="form-container-section">
        <span class="form-container-label"> Title * : </span>
        <span class="form-container-input">
          <InputText name="title" v-model="event.title" size="150"/>
        </span>
      </div>
      <div id="subTitle" class="form-container-section">
        <span class="form-container-label"> Subtitle * : </span>
        <span class="form-container-input">
          <InputText name="subTitle" v-model="event.subTitle" size="150" />
        </span>
      </div>
      <div id="startDate" class="form-container-section">
        <span class="form-container-label"> Date * : </span>
        <span class="form-container-input">
          <Calendar v-model="event.date" 
            :showIcon="true"
            dateFormat="yy-mm-dd"
          />
        <small> &nbsp; Select the first day of training.</small>
        </span>
      </div>
      <!--<div id="endDate" class="form-container-section">
        <span class="form-container-label"> End Date * : </span>
        <span class="form-container-input">
          <Calendar v-model="event.endDate" 
            :showIcon="true"
            dateFormat="MM dd, yy"
          />
        </span>
      </div>-->
      <div id="consecutiveDays" class="form-container-section">
        <span class="form-container-label"> Consecutive Days? * : </span>
        <span class="form-container-input">
          <Checkbox v-model="event.isConsecutive" :binary="true" />
        </span>
      </div>
      <div id="location" class="form-container-section">
        <span class="form-container-label"> Location * : </span>
        <span class="form-container-input">
          <InputText name="location" size="50" v-model="event.location" />
        </span>
      </div>

      <div id="select-trainer" class="form-container-section">
        <span class="form-container-label"> Available Trainers * : </span>
        <span class="form-container-input">
          <Dropdown 
            style="width:250px;height:25px;text-align:left;"
            :options="trainers"
            optionLabel="name"
            placeholder="Select a Trainer"
            v-model="trainer"
          >
          <template #value="slotProps">
            <div v-if="slotProps.value">
                <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
                {{slotProps.placeholder}}
            </span>
          </template>

          <template #option="slotProps">
              <div>
                  <div><img class="profile-image" name="trainerimage" :src="baseURL + trainerImagesPath + slotProps.option.imageFilename"> {{slotProps.option.name}}, {{slotProps.option.title}}</div>
              </div>
          </template>
        </Dropdown>&nbsp;
        <Button 
          class="p-button-raised p-button-rounded p-button-lg"
          icon="pi pi-plus"
          @click="addTrainer()"
        />
        </span>
      </div>

      <div id="location" class="form-container-section">
        <span class="form-container-label"> Selected Trainers * : </span>
        <span class="form-container-input message" v-if="selectedTrainers.length <= 0">
          No trainers selected! 
        </span>
        <span class="form-container-input" v-if="selectedTrainers.length >= 1">
          <span v-for="(trainer, index) in selectedTrainers" :key="trainer.id">
            <img class="selected-profile-image" name="trainerimage" :src="baseURL + trainerImagesPath + trainer.imageFilename" @click="removeTrainer(index)">&nbsp;
          </span>
        </span>
      </div>


      <!--<div id="trainerSelect" class="form-container-section">
        <span class="form-container-label"> Trainer * : </span>
        <span class="form-container-input">
          <Dropdown 
            style="width:250px;height:25px;text-align:left;"
            :options="trainers"
            optionLabel="name"
            placeholder="Select a Trainer"
            v-model="trainer"
          >
          <template #value="slotProps">
            <div v-if="slotProps.value">
                <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
                {{slotProps.placeholder}}
            </span>
          </template>

          <template #option="slotProps">
              <div>
                  <div>{{slotProps.option.id}} : {{slotProps.option.name}}, {{slotProps.option.title}}</div>
              </div>
          </template>
        </Dropdown>
        </span>
      </div>
      <div id="trainerProfile" class="form-container-section">
        <span class="form-container-label">Profile : </span>
        <span class="form-container-input">
          <TrainerProfile 
            :profile="trainer"
          />
        </span>
      </div>-->
      <div id="description" class="form-container-section">
        <span class="form-container-label"> Description * : </span>
        <span class="form-container-input">
          <Editor
            v-model="event.description"
            editorStyle="height: 320px"
          />
        </span>
      </div>
      <div id="program" class="form-container-section">
        <span class="form-container-label"> Program * : </span>
        <span class="form-container-input">
          <Editor
            v-model="event.program"
            editorStyle="height: 320px"
          />
        </span>
      </div>
      <div id="accommodations" class="form-container-section">
        <span class="form-container-label"> Accommodations * : </span>
        <span class="form-container-input">
          <Editor
            v-model="event.accommodation"
            editorStyle="height: 320px"
          />
        </span>
      </div>
      <div id="imageUpload" class="form-container-section" v-show="!event.imageFilename">
        <span class="form-container-label"> Image * : </span>
        <span class="form-container-input">
          <FileUpload 
            name="files[]" 
            :url="`${baseURL}/api/v1/upload/event/images`" 
            @error="uploadError" 
            @upload="uploadComplete" 
          />
        </span>
      </div>

      <div class="form-group form-group-sm" v-show="uploadedImageFileName || imageFilename">
        <label class="col-xs-12 col-sm-2 control-label" for="formGroupInputSmall">Uploaded Image</label>
        <div class="required">&nbsp;&nbsp;</div>
        <div class="section-event-image">
          <div id="preview-container">
            <div id="preview-header-title">Preview</div>
            <div id="preview-body-image">
              <img v-show="(imageFilename || event.imageFilename)  && !modifyImage" :src="baseURL + eventImagesPath + (imageFilename || uploadedImageFileName)" class="rounded">
              <preview
                v-show="(!imageFilename && !event.imageFilename) || modifyImage"
                class="preview-result-example__preview"
                :image="result.image"
                :coordinates="result.coordinates"
                :width="300"
                :height="300"
              />
            </div><br/>
            <div id="preview-footer-menu">
              <button v-show="!modifyImage" @click="modifyImage = !modifyImage">Modify</button>
              <button v-show="modifyImage" @click="saveImageToFileSystem()">Save</button>
              <button v-show="modifyImage" @click="modifyImage = !modifyImage">Cancel</button>
              <button v-show="!modifyImage" @click="deleteImageFromFileSystem(true, uploadedImageFileName || imageFilename)">Delete</button>
            </div>
          </div>
          <div v-if="modifyImage">
            Tool START
            <cropper
              ref="cropper"
              class="preview-result-example__cropper"
              :src="baseURL + eventImagesPath + (uploadedImageFileName || imageFilename)"
              :stencil-component="$options.components.CircleStencil"
              :default-size="{ width: 300, height: 300 }"
              :canvas="{ height: 300, width: 300 }"
              @change="onChange"
              :debounce="false"
            />
            Tool END
          </div>
        </div>
      </div>

      <h2 style="text-align: left;">Registration Information</h2>
      <div id="externalRegistrationURL" class="form-container-section">
        <span class="form-container-label"> External Registartion URL * : </span>
        <span class="form-container-input">
          <InputText name="externalRegistrationURL" v-model="event.externalRegistrationURL" size="75"/>
        </span>
      </div>
      <div id="regDates" class="form-container-section">
        <span class="form-container-label"> Opens * : </span>
        <span class="form-container-input">
          <Calendar v-model="event.regOpen" 
            :showIcon="true"
            dateFormat="yy-mm-dd"
          />
        </span>
        <span class="form-container-label"> Closes * : </span>
        <span class="form-container-input">
          <Calendar v-model="event.regClose" 
            :showIcon="true"
            dateFormat="yy-mm-dd"
          />
        </span>
        <span class="form-container-label"> Seats * : </span>
        <span class="form-container-input">
          <InputNumber name="seats" v-model="event.seats" />
        </span>
      </div>
      <div id="costProvince" class="form-container-section">
        <span class="form-container-label"> Cost * : </span>
        <span class="form-container-input">
          <span class="p-inputgroup-addon">$</span>
          <InputNumber mode="currency" currency="CAN" locale="en-CA" v-model="event.cost" />
        </span>
        <span class="form-container-label"> Province * : </span>
        <span class="form-container-input">
          <Dropdown :options="provinces" optionLabel="name" optionValue="code" placeholder="Select a province" v-model="event.provinceId" />
        </span>
      </div>

      <div id="submitButton">
        <Button 
          class="p-button-raised p-button-rounded p-button-lg"
          label="Update"
          icon="pi pi-check"
          @click="updateEvent()"
        />
      </div>

    </div>
    </div>
    <cmsFooter/>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useToast } from 'primevue/usetoast'

// 3rd Party Libraries and Helpers
import Button       from 'primevue/button'
import Editor       from 'primevue/editor'
import Calendar     from 'primevue/calendar'
import Checkbox     from 'primevue/checkbox'
import Dropdown     from 'primevue/dropdown'
import InputText    from 'primevue/inputtext'
import FileUpload   from 'primevue/fileupload'
import InputNumber  from 'primevue/inputnumber'
import RadioButton  from 'primevue/radiobutton'
import Toast from 'primevue/toast'

//Image Management
import { Cropper, CircleStencil, Preview } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

// Components
import cmsHeader from '../../../components/cms/cmsHeader.vue'
import cmsFooter from '../../../components/cms/cmsFooter.vue'
//import TrainerProfile from '../../../components/cms/Trainer.vue'

// Schema
import Trainer from '../../../schemas/Trainer.js'


// API
import TrainingServices from '../../../services/TrainerServices.js'
import CmsServices from '../../../services/CmsServices.js'
import EventServices from '../../../services/EventServices.js'


export default {
  name: 'cmsEventEdit',
  components: {
    Toast,
    Button,
    Editor,
    //TrainerProfile,
    Dropdown,
    Calendar,
    Checkbox,
    cmsHeader,
    cmsFooter,
    InputText,
    FileUpload,
    InputNumber,
    RadioButton,
    Cropper,
    CircleStencil,
    Preview
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const toast = useToast()
    const environment = process.env.VUE_APP_ENVIRONMENT
    const baseURL = process.env.VUE_APP_API_SERVER
    const trainerImagesPath = process.env.VUE_APP_TRAINER_IMAGES
    const eventImagesPath = process.env.VUE_APP_EVENT_IMAGES

    // Image Management
    const result = ref ({ coordinates: null, image: null })
    const uploadedImageFileName = ref('')
    const originalImageFilename = ref('')
    const finalImage = ref(null)
    const modifyImage = ref(false)
    const imageFilename = ref('')

    const provinces = [
      { name: 'Alberta', code: 1 },
      { name: 'British Columbia', code: 2 },
      { name: 'Manitoba', code: 3 },
      { name: 'New Brunswick', code: 4 },
      { name: 'Newfoundland and Labrador', code: 5 },
      { name: 'Northwest Territories', code: 6 },
      { name: 'Nova Scotia', code: 7 },
      { name: 'Nunavut', code: 8 },
      { name: 'Ontario', code: 9 },
      { name: 'Prince Edward Island', code: 10 },
      { name: 'Quebec', code: 11 },
      { name: 'Saskatchewan', code: 12 },
      { name: 'Yukon', code: 13 },
      { name: 'Other Country', code: 15 }
      ]

    const formatDate = (dateString) => {
      if(dateString) { 
        return dateString.substring(0,10)
      } 
    }

    const trainer = ref(new Trainer())//ref(store.state.trainers.find((trainer) => trainer.guid === route.query.trainerGuid))
    const trainers = ref([new Trainer()])//ref(store.state.trainers)
    const selectedTrainers = ref([])
    const event = ref(store.state.events.find((event) => event.guid === route.query.eventGuid))
    console.log(event.value)
    event.value.date = formatDate(event.value.date)
    event.value.regOpen = formatDate(event.value.regOpen)
    event.value.regClose = formatDate(event.value.regClose)
    imageFilename.value = event.value.imageFilename
    const existingImageFilenameFromDatabase = event.value.imageFilename.split('_')
    if(existingImageFilenameFromDatabase.length >= 2) {
      originalImageFilename.value = event.value.imageFilename.split('_')[1]
    } else {
      originalImageFilename.value = event.value.imageFilename
    }
    //event.value.endDate = formatDate(event.value.endDate)

    TrainingServices.getTrainingResources()
    .then((response) => {
      if(response.status != 200) {
        toast.add({severity: 'info', summary:'Information', detail: 'Something went wrong retrieving trainers!', life: 5000})
      } else {
        trainers.value = response.data
        // Use the event.trainers value to look up the full trainer and add to the selected trainers for display
        for (let i = 0; i < event.value.trainers.length; i++) {
          selectedTrainers.value.push(trainers.value.find(trainer => trainer.id === event.value.trainers[i]))
        }
      }
    })

    const updateEvent = () => {
      event.value.date = new Date(event.value.date).toISOString().slice(0,-5)+"Z"
      //event.value.endDate = new Date(event.value.endDate).toISOString().slice(0,-5)+"Z"
      if(event.value.regOpen) {event.value.regOpen = new Date(event.value.regOpen).toISOString().slice(0,-5)+"Z"}
      if(!event.value.regOpen) { event.value.regOpen = undefined }
      if(event.value.regClose) {event.value.regClose = new Date(event.value.regClose).toISOString().slice(0,-5)+"Z"}
      if(!event.value.regClose) { event.value.regClose = undefined }

      // Handle the trainers array so it just has strings
      event.value.trainers = selectedTrainers.value.map(trainer => trainer.id)

      // Remove prefix from url
      event.value.externalRegistrationURL = event.value.externalRegistrationURL.replace(/(^\w+:|^)\/\//, '');

      event.value.imageFilename = imageFilename.value || uploadedImageFileName.value

      store.dispatch('updateEvent', event.value)
      .then((response) => {
        event.value.date = formatDate(event.value.date)
        event.value.regOpen = formatDate(event.value.regOpen)
        event.value.regClose = formatDate(event.value.regClose)
        toast.add({severity:'success', summary: 'Success', detail: response, life: 5000})
        //event.value = store.state.events.find((event) => event.guid === route.query.eventGuid)
      })
      .catch((error) => {
        toast.add({severity:'error', summary:'Save Error', detail:error, life: 5000})
      })
    }

    const deleteImageFromFileSystem = (uiRequest, fileName) => {
      CmsServices.deleteSingleImageFromFileSystem(fileName)
      .then((response) => {
        if(response.status != 200) {
          toast.add({severity: 'info', summary: 'Information', detail: response.data, life: 5000})
        } else {
          if(uiRequest && uploadedImageFileName.value){
            uploadedImageFileName.value = ''
            toast.add({severity: 'success', summary: 'Success', detail: "Image deleted!", life: 5000})
          } 
          if(uiRequest && imageFilename.value) {
            imageFilename.value = ''
            toast.add({severity: 'success', summary: 'Success', detail: "Image deleted!", life: 5000})
          }
        }
        event.value.imageFilename = imageFilename.value // ''
      })
      .catch((error) => {
        toast.add({severity: 'error', summary: 'Delete Image Error', detail: error.message, life: 5000})
      })
    }

    const uploadComplete = (data) => {
      const result = JSON.parse(data.xhr.response)
      //event.value.imageFilename = result.filename[0]
      originalImageFilename.value = data.files[0].name // IE: test-05.png
      event.value.imageFilename = result.filename[0]
      uploadedImageFileName.value = result.filename[0] // IE: CCC2A2E1-15BD-49F9-804D-397480A670EA_test-05.png
      //toast.add({severity:'success', summary: 'Success', detail:result.msg, life: 5000})
    }

    const uploadError = () => {
      toast.add({severity:'error', summary: 'Upload Error', detail:'Failed to upload the image!', life: 5000})
    }

    const isComplete = (data) => {
      for (const property in data) {
        if (typeof event[property] === 'undefined') { return false }
      }
      return true // Everything is complete
    }

    const addTrainer = () => {
      if(selectedTrainers.value.indexOf(trainer.value) < 0) { 
        selectedTrainers.value.push(trainer.value)
      } 
    }

    const removeTrainer = (index) => {
      selectedTrainers.value.splice(index, 1)
    }

    const onChange = ({ coordinates, image, canvas }) => {
      result.value = {
        coordinates, image
      }
      finalImage.value = canvas.toDataURL('image/png').split(';base64,')[1]
    }

    // Used when the image is modified
    const saveImageToFileSystem = () => {
      EventServices.saveImageToFileSystem(originalImageFilename.value, finalImage.value)
      .then((response) => {
        if(response.status != 200) {
          toast.add({severity: 'info', summary: 'Information', detail: response.data, life: 5000})
        } else {
          toast.add({severity: 'success', summary: 'Success', detail: "Image Saved!", life: 5000})
          if(uploadedImageFileName.value) {
            deleteImageFromFileSystem(false, uploadedImageFileName.value)
            uploadedImageFileName.value = ''
          } if(imageFilename.value) {
            deleteImageFromFileSystem(false, imageFilename.value)
            imageFilename.value = ''
          }
          imageFilename.value = response.data.filename[0]
          modifyImage.value = false
        }
      })
      .then(() => {
        // Update the event record automatically
        updateEvent()
      })
    }

    return {
      environment,
      trainerImagesPath,
      eventImagesPath,
      provinces,
      saveImageToFileSystem,
      deleteImageFromFileSystem,
      updateEvent,
      uploadComplete,
      uploadError,
      isComplete,
      baseURL,
      event,
      trainer,
      trainers,
      addTrainer,
      removeTrainer,
      selectedTrainers,
      onChange,
      result,
      uploadedImageFileName,
      modifyImage,
      imageFilename
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: grid;
  grid-template-columns: 100%;
  justify-content: center;
}
.title {
  font-size: 22px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 15px;
}
.content {
  margin-top: 10%;
  text-align: left;
}
.action-bar {
  display: grid;
  justify-content: right;
  margin-top: 5%;
}
.form-container {
  margin-top: 1%;
  margin-bottom: 15%;
}
.form-container-section {
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
}
.form-container-label {
  display: flex;
  font-weight: 800;
  justify-content: flex-start;
  width: 20%;
}
.form-container-input {
  display: flex;
  width: 80%;
}
label {
  padding-left: 10px;
  padding-right: 10px;
}
.blog-image-menu-trash {
  font-size: 2rem;
  color: red; 
  cursor: pointer;
  margin-top: 5px;
}
.profile-image {
  width: 25px;
  border-color: Green;
  border-style: solid;
  border-width: 1px;
  border-radius: 75px;
  background-color: green;
}
.selected-profile-image {
  width: 100px;
  border-color: Green;
  border-style: solid;
  border-width: 1px;
  border-radius: 75px;
  background-color: green;
}
.message {
  color: red;
  font-weight: 550;
  font-size: 1.25em;
}

.preview-result-example {
	display: flex;
	&__cropper {
		width: 300px;
	}
	&__previews {
		margin-left: 32px;
	}
	&__preview {
		border-radius: 50%;
		overflow: hidden;
		margin-top: 24px;
		margin-bottom: 24px;
		width: 100px;
		height: 100px;
		&--small {
			width: 60px;
			height: 60px;
		}
	}
	&__preview-image {
		width: 100%;
	}
	&__button {
		position: absolute;
		left: 16px;
		bottom: 0;
	}
}
</style>
